.loan-wrapper{
    .user-name{
        padding: 0px;
    }
    .loan-title{
        padding: 0px;
    }
}
.loan-title{
    margin-bottom: 20px;
}
.card-wrapper{
    flex-direction: row;
    padding: 30px;
    border-radius: $border-radius-lg;
}
.card-shadow{
    box-shadow:$box-shadow;
}
.card-margin{
    margin-left: 11px;
}
.card-container{
    display: flex;
    align-items: stretch;     
}
.text-margin{
    margin-bottom: 30px;
}
.price-color{
    color: $teal;
}
.message-heading{
    margin-top: 20px;
    margin-bottom: 20px;
    div{
        padding: 0px;
    }
}
.messages-date{
    padding: 25px;
    color: $teal;
    font-size: 17px;
}
.documents-container{
    padding:30px;
    background-color: $blue;
    color: $white;
    border-radius: $border-radius-lg;
    h5{
        color: $white;
    }
}
.btn-loan{
    border-radius: 30px;
}

.container-timeline {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  .progressbar-icons li {
  list-style: none;
  display: inline-block;
  width: 19.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 19.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
  &:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height : 30px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: $white;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    top: 14px;
    left: -50%;
    z-index : -1;
  }
  
}

}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: $teal;
}
.progressbar li.active:before {
  border-color: $teal;
} 
.progressbar li.active + li:after {
  background-color: $teal;
}