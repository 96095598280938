.reviewResult-container{
    justify-content: center;
    margin-top: 20px;
    align-items: flex-start;
    .circel-control{
        height: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px;
        box-shadow: $box-shadow-lg;
        border-radius:$border-radius-lg;
    .progress-circel{
        width: 160px;
        height: 160px;
    }
    .course-section{
        padding: 15px;
        margin-top: 20px;
        border: $border-width solid $border-color;
        border-radius:$border-radius-lg;
        .course{
            display: flex;
            .icon1{
                margin-right: 10px;
                width: 20px;
                height: 20px;
                background-color: "#34c38f";
                border-radius: 5px;
            }
            .icon2{
                margin-right: 10px;
                width: 20px;
                height: 20px;
                background-color: "#5271FF";
                border-radius: 5px;
            }
        }
    }
    .quiz-history{
    margin-top: 40px;
    width: 100%;
    text-align: center;
        a{
            margin-top: 20px;
        }
    }

}

.quiz-start-wrapper{
    box-shadow: $box-shadow-lg;
    border-radius:$border-radius-lg;
    padding: 50px;
    margin-bottom: 20px;
    .mastery-score{ 
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-bottom: 2px solid $border-color;
        .mastery-circel{
            width: 150px;
            height: 150px;
        }
        .start-quiz{
            width: 100%;
            margin-top: 20px;
        }
    }
.score-wrapper{
    border-bottom: 2px solid $border-color;
    padding-bottom: 35px;
    display: flex;
    justify-content: center;
    .score-history{
        border: 2px solid $border-color;
        border-radius: $border-radius-lg;
        padding:20px 0px;
        .progress-container{
            height: 20px;
            padding: 0px;
            border-radius: 15px;
            background-color: #E9ECEF;
            .progress-inner1{
                border-radius: 15px;
                
            }
            .progress-inner2{
                border-radius: 15px;
                z-index: 0;
                margin-left: -15px;
            }
        }
        .progress-wrapper{
            margin-bottom: 20px;
            margin-right: 10px;
        }
        .progress-width{
            flex-grow: 5;
        }
    }
}
}
.score-title{
    margin: 20px 0;
}


.checkpoint{
    border: 2px solid $border-color;
    border-radius: $border-radius-lg;
    justify-content: center;
    padding: 15px 5px;
    .checkpoint-body{
    .list-item{
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        box-icon{
            margin-right: 10px;
        }
    }
    .list-item-wrapper{
         border-bottom: 2px solid $border-color;
         padding: 10px;
    }

}
}
.icon1-title{
    color:$teal;
    font-weight: bold;
}
 .icon2-title{
    color:$blue;
    font-weight: bold;
}
}
