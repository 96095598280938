.header-control{
    margin-bottom: 20px;
    .settings-header{
    a{
    display: flex;
    align-items: center;
    font-size: 17px;
        box-icon{
            margin-right: 10px;
        }
    }

}

}
.settings-form{
    Form{
        margin: 0px;
        padding: 0px;
        .title{
            color: $teal;
        }
        }
    }
    .input-control{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    box-icon{
        cursor: pointer;
        }
    .cursor-pointer{
    cursor: pointer !important;
}
    input{
        margin-right: 10px;
        border-radius: 10px;

    }
}


.setting-content{
    .card-heading{
        color: $teal;
        font-size: 17px;
        margin-bottom: 10px;
    }
    .card-container{
        .card-col{
            display: flex;
            align-items: stretch;
           .card-content{
               justify-content: space-between;
           }
        }
    }
    .sub-heading{
        color: $black;
        font-weight: bold;
        
    }
    .cancel-subscription{
        text-align: center;
    }
}
.settings-footer{
    margin-top: 40px;
    align-items: center;
    .setting-footer-img{
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
}

