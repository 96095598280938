.spinner-2 {
    width:100px;
    height:100px;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#25b09b);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  }
  @keyframes s3 {to{transform: rotate(1turn)}}
  /**/
.container-spinner{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    grid-auto-rows: 330px;
    place-items:center;
  }
  
  * {
    box-sizing: border-box;
  }