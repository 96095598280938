@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: block;
    margin: auto;
  }

  .auth-logo-light {
    display: $display-none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// authentication full page

.auth-full-bg {

  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }


  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #5fd6c4;
  }
}

.auth-full-page-content {
  display: flex;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0);
        }

        &.active, &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.required-input::before {
  content: "*";
  color: red;
  margin-right: 5px;
}

.password-eye {
  cursor: pointer;
}

.input-group-password:focus {
  display: block!important;
}