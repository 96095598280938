.content-wrapper {
  border-bottom: 1px solid $border-color !important;
  padding: 15px 10px !important;
  .sidebar-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .icon {
      margin-right: 17px;
    }
    .link {
      a {
        text-decoration: none;
        color: $sidebar-dark-menu-item-color;
        font-size: 15px;
      }
    }
  }
}
// .active{
//     border-left: 2px solid #5fd6c4;
// }
.shadow {
  box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 1);
}
.topbar {
  display: flex;
  padding: 10px 43px;
  justify-content: space-between;
  align-items: center;
  .details {
    display: flex;
    align-items: center;
    box-icon {
      border: 3px solid #5fd6c4;
      width: 45px;
      height: 45px;
      padding: 8px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .details-body a {
      margin-left: 10px;
      color: #5fd6c4;
    }
  }
}
.referrals-box {
  padding: 10px 45px;
  @media screen and (max-width: 667px){

      padding: 10px 20px;
  }
  .intro-area {
    h1 {
      color: $black;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      position: relative;
      .card-details {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .card-body1 {
          text-align: center;
        }
      }
      .line-top {
        width: 160px;
        height: 60px;
        position: absolute;
        top: -15px;
        left: 28%;
        z-index: -1;
        background: transparent;
        border: none;
        border-top: dashed 2px $gray-400;
        padding: 40px 40px;
        border-radius: 50%;
        margin: 20px -50px 0;
      }
      .line-bottom {
        width: 120px;
        height: 60px;
        position: absolute;
        top: 9px;
        right: 31%;
        background: transparent;
        border: none;
        border-bottom: dashed 2px $gray-400;
        padding: 20px 75px;
        border-radius: 0 0 50% 50%;
        margin: 0 -65px;
      }
    }
    .card-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      background-color: $toast-background-color;
      border: 1px solid transparent;
      border-radius: 50%;
      margin-bottom: 10px;
    }
  }
}
.invite {
  margin-top: 40px;
  .title {
    h3 {
      color: $black;
    }
  }
  .mail {
    position: relative;
  }
  .form-group {
    margin-bottom: 0px;
    padding-left: 0px;
    input {
      border-radius: 30px;
      padding: 20px;
    }
  }
  .icon {
    width: 32px;
    height: 32px;
    background-color: #5fd6c4;
    position: absolute;
    top: 22%;
    right: 3%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .share-link {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 1rem;
    .form-group {
      flex-grow: 1;
    }
    @media screen and (max-width: 667px){
      width: 100%;
      display: inline-block;
    }
    .copy-link {
      position: absolute;
      top: 33%;
      right: 20%;
      margin-right: 1rem;
      @media screen and (max-width: 667px){
        top: 23%;
        right: 1%;
      }
      a {
        color: #5fd6c4;
      }
    }
    .social-icons {
      display: flex;
      margin-left: 10px;
      .social-icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: $toast-background-color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      @media screen and (max-width: 667px){
        justify-content: center;
        margin-top: 10px;
      }
    }
  }
}
