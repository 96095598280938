body {
  overflow-x: hidden;
}
.pt-5 {
  padding-top: 5rem !important;
}
.max-width {
  width: 90% !important;
}
.aheto-heading {
  width: 230px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cffaf1;
  border-radius: 30px;
  margin-bottom: 20px;
  h5 {
    color: '#3BB4A1';
    margin: 0px;
  }
}
.fixed-top {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.navbar-container {
  background-color: #76e4d3;
  padding: 0px 40px;
  @media only screen and (max-width: 768px) {
    padding: 0px;
    .navbar-toggler {
      margin-right: 20px;
    }
  }

  .page-logo {
    @media only screen and (max-width: 768px) {
      padding-left: 20px;
    }
    img {
      width: 150px;
      height: 60px;
      object-fit: cover;
    }
  }
  ul {
    li {
      @media only screen and (max-width: 768px) {
        background-color: $white;
        border: 1px solid #dadada;
        text-align: center;
        margin: 0px !important;
        .cta-button {
          width: 100%;
          border-radius: 0px;
        }
      }
      a {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: $white !important;
        @media only screen and (max-width: 768px) {
          color: #000 !important;
        }
      }
    }
  }
}

.banner-container {
  // background-image: url('../../../images/landingpage/image1.png');
  width: 100%;
  min-height: 80vh;
  background-color: #76e4d3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .hero-section {
    padding: 0px !important;
    color: $black;
    @media only screen and (max-width: 576px) {
      padding: 30px 0px !important;
    }
    h1 {
      color: $black;
      font-size: 48px;
      text-align: left;
      font-weight: 700;
    }
    @media only screen and (max-width: 576px) {
      h1 {
        font-size: 30px;
        text-align: center;
      }
    }
    p {
      font-size: 22px;
    }
    ol {
      li {
        font-size: 22px;
      }
    }
    .hero-img img {
      width: 500px;
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }
}
.elementor-section {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  @media only screen and (max-width: 376px) {
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .inner-element-section-2 {
    height: 480px !important;
    .inner-element-1 {
      top: 5% !important;
      left: 5% !important;
    }
  }
  .dashboard {
    background-color: transparent !important;
  }
  .inner-element {
    min-height: 560px;
    max-width: 100%;
    background-color: #5271ff;
    border-radius: $border-radius-lg;
    padding: 16px;
    @media only screen and (max-width: 576px) {
      min-height: 510px;
    }
    @media only screen and (max-width: 376px) {
      max-height: 300px;
    }

    .element-wrapper {
      position: relative;
      width: 100%;
      .dashboard-img {
        width: 100%;
        height: 100%;
      }
      .inner-1 {
        width: 100%;
        position: absolute;
        top: 11%;
        z-index: 1;
        @media only screen and (max-width: 576px) {
          width: 100%;
          left: 0;
          top: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }
  .task-container {
    padding: 063px 0px 63px 100px;
    @media only screen and (max-width: 576px) {
      padding: 40px;
    }
  }
  .aheto-body {
    margin-bottom: 20px;
    h1 {
      margin-bottom: 1.5rem;
    }
    P {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #242230;
      font-style: normal;
    }
  }
  .aheto-link {
    font-size: 17px;
    font-weight: 500;
  }
  .task-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    div {
      flex: 40%;
      background-color: $white;
      border-radius: $border-radius-lg;
      padding: 10px 30px;
      box-shadow: $box-shadow;
      margin-bottom: 10px;
      margin-right: 12px;
      text-align: center;
    }
  }
  .elementor-element {
    padding: 75px 100px 75px 12px;
    @media only screen and (max-width: 576px) {
      padding: 0px 50px;
    }
  }
}
.elemenator-top-section {
  background-image: url('../../../images/landingpage/brand/Section-1.png');
  background-position: top center;
  background-repeat: repeat;
  background-size: cover;
  padding: 100px 0px 130px 0px;
  @media only screen and (max-width: 576px) {
    padding: 40px;
  }
}

.card-container {
  padding: 40px 30px;
  .card-img {
    width: 90%;
  }
  .card-body {
    padding: 25px 0 0 0;
  }
  .card-link {
    border-bottom: 1px solid $blue-200;
    width: 60%;
  }
}
.section-31 {
  padding: 50px 0px 50px 0px;
  background-image: url('../../../images/landingpage/brand/Section-31.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 576px) {
    padding: 40px;
  }
  .section-31-container {
    background-color: $gray-200;
    border-radius: $border-radius-lg;
    padding: 70px 70px 40px 70px;
    @media only screen and (max-width: 576px) {
      padding: 40px;
    }
  }
}
.price-section {
  padding: 130px 0px 130px 0px;
  background-color: #5271ff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .price-section-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $white;
    h1 {
      font-size: 36px;
      line-height: 1.35;
      color: $white;
    }
    p {
      max-width: 620px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: #ffffff;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }
  }
  .price-title {
    color: $black;
    font-size: 36px;
    line-height: 1.35;
    font-weight: 700;
  }
  .price-desc {
    font-size: 18px;
    line-height: 26px;
    color: $black;
  }
  .price-wrapper {
    font-size: 20px;
    color: $black;
    .price {
      color: $black;
      font-size: 72px;
      font-weight: 900;
    }
  }
  .price-details {
    list-style: none;
    li {
      margin-bottom: 12px;
      font-weight: 400;
      line-height: 1.5em;
      color: $black;
      position: relative;
      &:before {
        content: '';
        background-color: $blue;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 6px;
        left: -15px;
        border-radius: 50%;
      }
    }
  }
  .brnad-logo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    li {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.2s linear;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.our-software {
  padding: 100px 0px 0px 0px;
  background-image: url('../../../images/landingpage/brand/Section-31.png');
  background-position: 0px -180px;
  background-size: cover;
  .our-software-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .second-card-img {
    width: 40%;
  }
}
.purchase-now {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.cta-button {
  background-color: #5271ff;
  border-color: #5271ff;
  font-size: 18px;
  color: #fff;
}
.page-footer {
  padding: 100px 0px 80px 0px;
  background-color: #5271ff;
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: space-between;
    .footer-heading {
      font-size: 22px;
      font-weight: bold;
      color: $white;
      margin-bottom: 12px;
      .footer-logo {
        width: 115px;
        height: 40px;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .footer-item {
      margin-top: 12px;
      font-weight: normal;
      li {
        margin-bottom: 12px;
        font-size: 16px !important;
        a {
          color: $white !important;
          font-size: 16px !important;
        }
      }
    }
  }
}
