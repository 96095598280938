.AppNewVersionPopup
  position: fixed
  z-index: 10000
  left: 0
  right: 0
  margin: 0 auto
  width: 250px
  top: 5%
  max-width: calc(100% - 20px)
  background: #333a50
  color: #fff
  border: 1px solid #fff
  border-radius: 10px
  padding: 16px 16px
  text-align: center
  animation: popup 1s ease

.update__title
  font-size: 1rem
  font-weight: 550
  margin-bottom: 8px

.update__desc
  cursor: pointer
  color: #5fd6c4
  font-size: 1rem
  font-weight: 500

@keyframes popup
  from
    top: -5%
    opacity: .1
  to
    top: 5%
    opacity: 1
