// variable
$primaryColor: #000;
$secondaryColor: #aaaaa6;
$lightSecondaryColor: #f5f3f0;

.pointer {
  cursor: pointer;
}

.heading {
  h1 {
    color: $primaryColor;
    text-align: center;
    margin-bottom: 25px;
  }
  h3 {
    color: $primaryColor;
  }
}
.review-body {
  border-bottom: 1px dashed $secondaryColor;
  margin-bottom: 20px;
  p {
    color: $secondaryColor;
    font-size: 14px;
  }
}
.stars-icons {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 15px;
    li {
      margin: 0px;
      list-style-type: none;
      margin-right: 10px;
      cursor: pointer;
      box-icon {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
.like-icons {
  ul {
    display: flex;
    list-style-type: none;
  }
  li {
    margin-right: 40px;
    box-icon {
      width: 40px;
      height: 40px;
    }
  }
}
.chip {
  display: inline-block;
  padding: 0 25px;
  margin-bottom: 15px;
  margin-left: 10px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
  border-radius: 25px;
  background-color: $lightSecondaryColor;
  p {
    color: $primaryColor;
  }
}
.publish-button {
  margin-bottom: 15px;
  border-radius: 25px;
  background-color: #5FD6C4;
  color:#5FD6C4;
}
