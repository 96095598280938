.ml-4 {
  margin-left: 1.5rem;
}
.left-side-container-wrapper{
  display: flex;
  justify-content: center;
}
.left-side-container { 
  width: 700px;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }


  .top-card {
    .list-items-container {
      color: white;
    }
    .card-actions-container {
      margin-top: 20px;
      .col {
        @media only screen and (max-width: 576px) {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
        @media only screen and (max-width: 400px) {
          flex-direction: column;
        }
        .btn-see-details,
        .btn-apply-now {
          height: 40px;
          padding: 0px 20px;
          background: none;
          border: none;
          outline: none;
          color: white;
          font-weight: 600;
          border-radius: 10px;
          float: right;
          :focus {
            outline: none;
          }
        }
        .btn-see-details {
          border: 1px solid #fff;
        }
        .btn-apply-now {
          background-color: white;
          margin-left: 10px;
          @media only screen and (max-width: 400px) {
            margin-bottom: 15px;
        }
        }
      }
    }
  }

  .bottom-cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .bottom-card {
      .sub-title {
        margin-top: 15px;
        font-size: 12px;
      }
      .bar-container {
        width: 15px;
        height: 50px;
        border-radius: 10px;

        background-color: #d3d3d35c;
        .bar-value {
          border-radius: 10px;
          background-color: #5fd6c4;
          height: 25px;
          margin-top: 25px;
        }
      }
      .personal-score {
        margin: 0;
        padding: 0;
        margin-left: 10px;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1px;
        .chevron-right {
          margin-left: 0px;
          font-size: 24px;
          font-weight: 300;
        }
      }
      .info-container {
        display: flex;
        align-items: center;
      }
    }

    .personal-credit-card {
      width: 280px;
    }

    .business-card {
      width: auto;
    }
  }
}
.right-side-container {
  margin-bottom: 30px;
  @media only screen and (max-width: 1250px) {
    display: flex ;
    justify-content: space-evenly;

  }
  @media only screen and (max-width: 792px) {
    flex-direction: column;
    align-items: center;
  }
  .right-side-card {
    max-width: 360px;
    @media only screen and (max-width: 1250px) {
        margin-top: 0px !important;
        width: 100%;
  
    }

  }

  .business-profile-card {
    .progress-bar-container {
      width: 150px;
      height: auto;
      margin-bottom: 20px;
    }
    .btn-and-info-container {
      .heading {
        font-size: 12px;
        font-weight: bold;
        color: #06060678;
      }
      .info {
        font-size: 14px;
        font-weight: 600;
        color: #000000b3;
      }
      .business-profile-btn {
        background: none;
        border: none;
        outline: none;
        background-color: #0089ff24;
        height: 30px;
        width: 70px;
        border-radius: 10px;
        color: #007eff;
        font-weight: 500;
        font-size: 14px;
      }

      .business-profile-completed-btn {
        background: none;
        border: none;
        outline: none;
        color: #5fd6c4;
        font-size: 14px;
      }
    }
  }
}

.custom-card {
  padding: 20px;
  border-radius: 10px;
  background-color: white;

  .card-heading {
    margin: 0;
    padding: 0;
    color: white;
  }

  .card-subheading {
    margin-top: 20px;
    font-size: 16px;
  }
}

.new-card {
  border-radius: 10px;
}

.cash-flow-card{
  border-radius: 10px;
  height: 420px
}

.img-dashboard{
  height: 180px;
  @media only screen and (max-width: 576px) {
    display: none;
  }

}

.img-icon{
  height: 120px;
  display: flex;
  align-items: center;
  margin: auto
}

.mini-stats-wid{
  .mini-stat-icon{
      overflow: hidden;
      position: relative;
      &:before, &:after{
          content: "";
          position: absolute;
          width: 8px;
          height: 54px;
          background-color: rgba($white,.1);
          left: 16px;
          transform: rotate(32deg);
          top: -5px;
          transition: all 0.4s;
      }

      &::after{
          left: -12px;
          width: 12px;
          transition: all 0.2s;
      }
  }

  &:hover{
      .mini-stat-icon{
          &::after{
              left: 60px;
          }
      }
  }
}

.rounded-circle {
  background-color: "#5fd6c4";
  border-radius: 50% !important; }
  .update-goal-container{
    padding: 10px 50px;
    .update-goal-btn {
      background: none;
      border: none;
      outline: none;
      background-color: #0089ff24;
      height: 40px;
      width:100%;
      border-radius: 10px;
      color: #007eff;
      font-weight: 500;
      font-size: 14px;
    }
  }
.modal-header{
  .close{
    padding: 2rem !important;
  }
}
